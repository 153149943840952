import { NgModule } from '@angular/core'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { CoreModule } from './core/core.module'
import { SharedModule } from './shared/shared.module'
import { SyncfusionModule } from './syncfusion.module'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { ZeiterfassungComponent } from './pages/zeiterfassung/zeiterfassung.component'
import { AbwesenheitsuebersichtComponent } from './pages/abwesenheitsuebersicht/abwesenheitsuebersicht.component'
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular'
import { ConfigService } from './core/services/config.service'
import { APP_INITIALIZER } from '@angular/core'
import { ConfirmationDialogComponent } from './pages/Dialog Abstimmung/confirmationDialog.component'
import { LeaveRequestDialogComponent } from './pages/Dialog Abwesenheit/leaveRequestDialog.component';
import { EmployeesOverviewPageComponent } from './pages/employees-overview-page/employees-overview-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { UserSettingsDialogComponent } from './pages/Dialog UserSettings/userSettingsDialog.component'
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {YearlyOverviewComponent} from "./pages/yearly-overview/yearly-overview.component";
import {DayMarkersService, EditService, GanttModule, SelectionService} from "@syncfusion/ej2-angular-gantt";
import {FilterService, SortService, ToolbarService} from "@syncfusion/ej2-angular-grids";
import {MenuModule} from "@syncfusion/ej2-angular-navigations";
import {TimelineMonthService, TimelineViewsService, TimelineYearService} from "@syncfusion/ej2-angular-schedule";
import { NotifictionComponent } from './pages/notifiction/notifiction.component'
import { ContingentService } from './shared/services/contingent.service'
import { TimeEntryDialogComponent } from './pages/time-entry-dialog/time-entry-dialog.component';
import { CalendarModule } from '@syncfusion/ej2-angular-calendars'

registerLocaleData(localeDe);
function initializeKeycloak(keycloak: KeycloakService, configService: ConfigService) {
  return () => keycloak.init({
    config: {
      url: configService.config.keycloak.url,
      realm: configService.config.keycloak.realm,
      clientId: configService.config.keycloak.clientId
    },
    initOptions: {
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
    },
    loadUserProfileAtStartUp: true
  });
}

@NgModule({
  declarations: [
    AppComponent,
    ZeiterfassungComponent,
    AbwesenheitsuebersichtComponent,
    ConfirmationDialogComponent,
    LeaveRequestDialogComponent,
    EmployeesOverviewPageComponent,
		UserSettingsDialogComponent,
		YearlyOverviewComponent,
		NotifictionComponent,
    TimeEntryDialogComponent,
  ],
    imports: [
        CoreModule,
        SharedModule,
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        SyncfusionModule,
        AppRoutingModule,
        KeycloakAngularModule,
        ReactiveFormsModule,
        ReactiveFormsModule,
        FormsModule,
        GanttModule,
        MenuModule,
        CalendarModule
    ],
  providers: [
		HttpClient,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, ConfigService]
    },
		{ provide: LOCALE_ID, useValue: 'de' },
		EditService, FilterService, SortService, SelectionService, ToolbarService, DayMarkersService,
		TimelineViewsService,
		TimelineMonthService,
		TimelineYearService,
    ContingentService
	],
  bootstrap: [
		AppComponent,
]
})
export class AppModule {
}
