import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { ConfigService } from '../../../core/services/config.service'
import { Holiday } from '../models/holiday.model'
import { CreateHolidayDTO } from '../dto/create-holiday.dto'
import { UpdateHolidayDTO } from '../dto/update-holiday.dto'

@Injectable({
	providedIn: 'root'
})
export class HolidaysHttpRequestService {

	private readonly url: string

	public constructor(private readonly config: ConfigService, private readonly http: HttpClient) {

		this.url = `${this.config.baseUrl}/holidays`
	}

	public findPublicHolidays(year: number = new Date().getFullYear()): Observable<any> {
		return this.http.get<any>(`${this.url}/publicHolidays/${year}`);
	}

	public findGermanStates(): Observable<any> {
		return this.http.get<any>(`${this.url}/germanStates`)
	}


	public findAll(): Observable<Holiday[]> {

		return this.http.get<Holiday[]>(this.url)
	}

	public findOne(id: string): Observable<Holiday> {

		return this.http.get<Holiday>(`${this.url}/${id}`)
	}

	public create(holiday: Partial<Holiday>): Observable<Holiday> {

		const dto: CreateHolidayDTO = {
			date: holiday.date!,
			name: holiday.name!,
		}

		return this.http.post<Holiday>(this.url, dto)
	}

	public update(holiday: Holiday): Observable<Holiday> {

		const dto: UpdateHolidayDTO = {
			date: holiday.date,
			name: holiday.name,
		}

		return this.http.put<Holiday>(`${this.url}/${holiday.id}`, dto)
	}

	public delete(holiday: Holiday): Observable<Holiday> {

		return this.http.delete<Holiday>(`${this.url}/${holiday.id}`)
	}
}
