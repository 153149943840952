import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import {
	AbstractControl,
	FormBuilder,
	FormControl,
	FormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from '@angular/forms';

@Component({
	selector: 'app-time-entry-dialog',
	templateUrl: './time-entry-dialog.component.html',
	styleUrl: './time-entry-dialog.component.scss',
})
export class TimeEntryDialogComponent {
	@Output() closed: EventEmitter<string> = new EventEmitter<string>();
	@Output() onSave: EventEmitter<any> = new EventEmitter<any>();
	@Input() editTimeData: any;
	form: FormGroup;
	pauseWarningMessage: string = '';

	constructor(private fb: FormBuilder) {
		this.form = this.fb.group(
			{
				date: [new Date(), Validators.required],
				from: ['', [Validators.required]],
				to: ['', [Validators.required]],
				pause: ['', [this.isPauseTimeGreaterThanTotalTime()]],
				comment: [''],
			},
			{
				validators: [
					this.isGreaterThanValidator(),
					this.pauseRequiredValidator(),
				],
			}
		);
	}

	get fromValue() {
		return this.form?.controls['from'].value;
	}

	get toValue() {
		return this.form?.controls['to'].value;
	}

	set toValue(value: Date) {
		this.form.controls['to'].setValue(value);
	}

	get pauseValue() {
		return this.form?.controls['pause'].value;
	}

	ngOnInit() { }

	ngOnChanges(changes: SimpleChanges) {
		if (this.editTimeData) {
			// Convert time strings to Date objects, removing seconds
			const from = new Date();
			const [fromHours, fromMins] = this.editTimeData.from.split(':');
			from.setHours(fromHours, fromMins, 0);

			const to = new Date();
			const [toHours, toMins] = this.editTimeData.to.split(':');
			to.setHours(toHours, toMins, 0);

			const pause = new Date();
			const [pauseHours, pauseMins] = this.editTimeData.pause.split(':');
			pause.setHours(pauseHours, pauseMins, 0);

			this.form.patchValue({
				date: new Date(this.editTimeData.date),
				from: from,
				to: to,
				pause: pause,
				comment: this.editTimeData.comment
			});
		}
	}

	isPauseTimeGreaterThanTotalTime(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const to = this.toValue;
			const from = this.fromValue;
			const check = control.value ? (control.value as Date) : null;
			if (!to || !from || !check) {
				return null;
			}
			const diffMs = to.getTime() - from.getTime();
			const diffMins = Math.floor(diffMs / 60000); // Convert milliseconds to minutes

			const checkTime = check.getHours() * 60 + check.getMinutes();

			return checkTime < diffMins ? null : { total: true };
		};
	}

	isGreaterThanValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const to = control.get('to');
			const from = control.get('from');

			if (!to || !from) {
				return null;
			}

			if (!to.value || !from.value) {
				return null;
			}

			const toMs = to.value.getTime();
			const fromMs = from.value.getTime();

			const isValid = toMs > fromMs;

			if (isValid) {
				to.setErrors(null);
				from.setErrors(null);
				return null;
			} else {
				to.setErrors({ range: true });
				from.setErrors({ range: true });
				return { range: true };
			}
		};
	}

	pauseRequiredValidator(): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			if (this.isPauseRequired()) {
				return { pauseRequired: true };
			}
			return null;
		};
	}

	isPauseRequired() {
		const to = this.toValue;
		const from = this.fromValue;
		if (!to || !from) {
			return false;
		}
		const diffMs = to.getTime() - from.getTime();
		const diffMins = Math.floor(diffMs / 60000); // Convert milliseconds to minutes
		if (diffMins >= 540 && this.calculatePauseMins(this.pauseValue) < 45) {
			this.pauseWarningMessage =
				'Die Pause muss mindestens 45 Minuten betragen.';

			return true;
		}
		if (diffMins >= 360 && this.calculatePauseMins(this.pauseValue) < 30) {
			this.pauseWarningMessage =
				'Die Pause muss mindestens 30 Minuten betragen.';
			return true;
		}

		return false;
	}

	calculatePauseMins(pause: Date): number {
		if (!pause) {
			return 0;
		}
		return pause.getHours() * 60 + pause.getMinutes(); // Convert pause time to minutes
	}

	getControl(controlName: string) {
		return this.form.controls[controlName] as FormControl;
	}

	close() {
		this.closed.emit('closed');
	}

	onClickSave(){
		this.onSave.emit({id:this.editTimeData.id,...this.form.value});
	}
}
