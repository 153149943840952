<div class="App overlay">
    <div class="AbsenceDialog">
        <div class="heading">
            <h2><strong>Zeiteintag bearbeiten</strong></h2>
        </div>

        <form [formGroup]="form">
            <div class="fieldRow fieldRow-one">
                <div class="field">
                    <!-- Date -->
                    <ejs-datepicker locale="de" [firstDayOfWeek]="1" id="date" [placeholder]="'Datum'"
                        format="dd.MM.yyyy" formControlName="date"></ejs-datepicker>
                    <div *ngIf="getControl('date').errors as errors">
                        <div *ngIf="errors['required'] && getControl('date').touched" class="e-error">
                            Dieses Feld ist erforderlich.
                        </div>
                    </div>
                </div>
            </div>

            <div class="fieldRow time-picker-wrapper">
                <div class="time-picker">
                    <div class="field timepicker">
                        <!-- From -->
                        <ejs-timepicker [step]="+'15'" [placeholder]="'Start'" format="HH:mm"
                            formControlName="from"></ejs-timepicker>
                        <div *ngIf="getControl('from').errors as errors">
                            <div *ngIf="errors['required'] && getControl('from').touched" class="e-error">
                                Dieses Feld ist erforderlich.
                            </div>
                            <div *ngIf="errors['required'] && getControl('from').touched" class="e-error">
                                Die Endzeit kann nicht vor der Startzeit liegen.
                            </div>
                        </div>
                    </div>

                    <div class="field timepicker">
                        <!-- To -->
                        <ejs-timepicker [step]="+'15'" [placeholder]="'End'" format="HH:mm"
                            formControlName="to"></ejs-timepicker>
                        <div *ngIf="getControl('to').errors as errors">
                            <div *ngIf="errors['required'] && getControl('to').touched" class="e-error">
                                Dieses Feld ist erforderlich.
                            </div>
                            <div *ngIf="errors['required'] && getControl('to').touched" class="e-error">
                                Die Endzeit kann nicht vor der Startzeit liegen.
                            </div>
                        </div>
                    </div>

                    <div class="field timepicker">

                        <!-- Pause -->
                        <ejs-timepicker [step]="+'15'" [placeholder]="'Pause'" format="HH:mm"
                            formControlName="pause"></ejs-timepicker>
                        <div *ngIf="getControl('pause').errors as errors">
                            <div *ngIf="errors['required'] && getControl('pause').touched" class="e-error">
                                Dieses Feld ist erforderlich.
                            </div>
                            <div *ngIf="errors['total'] && getControl('pause').touched" class="e-error">
                                Die Pausenzeit darf nicht länger oder gleich der Gesamtzeit sein.
                            </div>
                        </div>
                        <div *ngIf="isPauseRequired()" class="e-error">
                            {{ pauseWarningMessage }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="fieldRow fieldRow-three">

                <!-- Comment -->
                <ejs-textbox style="width: 100%;" #textarea [placeholder]="'Kommentar'" [floatLabelType]="'Auto'"
                    [cssClass]="'e-'+''" rows="3" class="invalid" [multiline]="true"
                    formControlName="comment"></ejs-textbox>
            </div>

            <div class="fullRow">
                <button ejs-button [cssClass]="'e-'+''" [isPrimary]="+'0' == 1" (click)="close()">
                    Abbrechen
                </button>
                <button [disabled]="form.invalid" ejs-button [cssClass]="'e-'+''" [isPrimary]="+'1' == 1" (click)="onClickSave()">
                    Speichern
                </button>
            </div>
        </form>
    </div>
</div>