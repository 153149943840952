import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'

import { ConfigService } from '../../../core/services/config.service'
import { Employee } from '../models/employee.model'
import { CreateEmployeeDTO } from '../dto/create-employee.dto'
import { UpdateEmployeeDTO } from '../dto/update-employee.dto'

@Injectable({
	providedIn: 'root'
})
export class EmployeesHttpRequestService {

	private readonly url: string

	public constructor(private readonly config: ConfigService, private readonly http: HttpClient) {

		this.url = `${this.config.baseUrl}/employees`
	}

	public findAll(): Observable<Employee[]> {

		return this.http.get<Employee[]>(this.url)
	}

	public findOne(id: string): Observable<Employee> {

		return this.http.get<Employee>(`${this.url}/${id}`)
	}

	public create(employee: Partial<Employee>): Observable<Employee> {

		const dto: CreateEmployeeDTO = {
			firstname: employee.firstname!,
			lastname: employee.lastname!,
			role: employee.role!,
			email: employee.email!,
			isArchived: employee.isArchived!,
			birthday: employee.birthday!,
			startDate: employee.startDate!,
			outlet: employee.outlet!,
			stateToApply: employee.stateToApply!,
			timeEntryIds: employee?.timeEntries?.map(timeEntry => timeEntry.id) ?? [],
			targetVacationDaysIds: employee?.targetVacationDayses?.map(targetVacationDays => targetVacationDays.id) ?? [],
			targetWorkingHoursIds: employee?.targetWorkingHourses?.map(targetWorkingHours => targetWorkingHours.id) ?? [],
			abscenceIds: employee?.abscences?.map(abscence => abscence.id) ?? [],
		}

		return this.http.post<Employee>(this.url, dto)
	}

	public bulkCreate(file: FormData): Observable<any> {
		return this.http.post(`${this.url}/userBulkCreate`, file);
	}

	public update(employee: Employee): Observable<Employee> {

		const dto: UpdateEmployeeDTO = {
			firstname: employee.firstname,
			lastname: employee.lastname,
			role: employee.role,
			email: employee.email,
			isArchived: employee.isArchived,
			birthday: employee.birthday,
			startDate: employee.startDate,
			outlet: employee.outlet,
			stateToApply: employee.stateToApply,
			timeEntryIds: employee?.timeEntries?.map(timeEntry => timeEntry.id) ?? [],
			targetVacationDaysIds: employee?.targetVacationDayses?.map(targetVacationDays => targetVacationDays.id) ?? [],
			targetWorkingHoursIds: employee?.targetWorkingHourses?.map(targetWorkingHours => targetWorkingHours.id) ?? [],
			abscenceIds: employee?.abscences?.map(abscence => abscence.id) ?? [],
		}

		return this.http.put<Employee>(`${this.url}/${employee.id}`, dto)
	}

	public delete(employee: Employee): Observable<Employee> {

		return this.http.delete<Employee>(`${this.url}/${employee.id}`)
	}
}
