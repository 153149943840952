import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, tap } from 'rxjs';

import { Employee } from '../models/employee.model';
import { EmployeesHttpRequestService } from './employees-http-request.service';
import { HttpRequest } from '../../../engine/models/http-request.model';
import { EngineService } from '../../../engine/services/engine.service';
import { EmployeesCacheService } from './employees-cache.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeesService {
  private readonly updatedSubject: Subject<void> = new Subject<void>();
  public readonly updated$: Observable<void> = this.updatedSubject.asObservable();

  private employeesSubject = new BehaviorSubject<Employee[]>([]);
  public employees$ = this.employeesSubject.asObservable();

  public constructor(
    private readonly employeesHttpRequestService: EmployeesHttpRequestService,
    private readonly engineService: EngineService,
    private readonly employeesCacheService: EmployeesCacheService
  ) {
    this.engineService.appendProcessorForMany('Employee', this.processMany.bind(this));
    this.engineService.appendProcessorForOne('Employee', this.processOne.bind(this));
  }

  public findAll(): void {
    const request = this.employeesHttpRequestService.findAll();
    this.engineService.execute(new HttpRequest('Employee', 'findAll', request));
  }

  public findOne(id: string): void {
    const request = this.employeesHttpRequestService.findOne(id);
    this.engineService.execute(new HttpRequest('Employee', 'findOne', request));
  }

  public create(employee: Partial<Employee>, callback?) {
    const request = this.employeesHttpRequestService.create(employee);
    this.engineService.execute(new HttpRequest('Employee', 'create', request));
  }

  public bulkCreate(file: FormData) {
    const request = this.employeesHttpRequestService.bulkCreate(file);
    return this.engineService.execute(new HttpRequest('Employee', 'create', request));
  }  

  public update(employee: Employee): void {
    const request = this.employeesHttpRequestService.update(employee);
    this.engineService.execute(new HttpRequest('Employee', 'update', request));
  }

  public delete(employee: Employee): void {
    const request = this.employeesHttpRequestService.delete(employee);
    this.engineService.execute(new HttpRequest('Employee', 'delete', request));
  }

  public processMany(action: string, employees: Employee[]): void {    
    switch (action) {
      case 'findAll':
        this.employeesCacheService.employees = employees;
        break;
      case 'findOne':
        console.error('Invalid action', action);
        break;
      case 'create':
        this.employeesCacheService.insertOrUpdateMany(employees);
        break;
      case 'update':
        this.employeesCacheService.insertOrUpdateMany(employees);
        break;
      case 'delete':
        this.employeesCacheService.deleteMany(employees);
        break;
      default:
        console.error('Invalid action', action);
        break;
    }

    this.employeesSubject.next(this.employeesCacheService.employees);
    this.updatedSubject.next();
  }

  public processOne(action: string, employee: Employee): void {
    switch (action) {
      case 'findAll':
        console.error('Invalid action', action);
        break;
      case 'findOne':
        this.employeesCacheService.insertOrUpdateOne(employee);
        break;
      case 'create':
        this.employeesCacheService.insertOrUpdateOne(employee);
        break;
      case 'update':
        this.employeesCacheService.insertOrUpdateOne(employee);
        break;
      case 'delete':
        this.employeesCacheService.deleteOne(employee);
        break;
      default:
        console.error('Invalid action', action);
        break;
    }

    this.updatedSubject.next();
  }
}
